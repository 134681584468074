<app-loading-spinner *ngIf="showSpinner"></app-loading-spinner>

<div class="pb-3 mt2r hm">
  <div class="row">
    <div class="col p-0">
      <div *ngIf="case">
        <mdb-card cascade="true" class="bighero text-center">
          <!--Card image-->
          <div
            class="view view-cascade gradient-card-header default-color white-text"
          >
            <h2 class="card-header-title h2-responsive">
              {{ case.store_alias }} 訂單明細
              {{ storeActive ? "" : " (愛露營與店家已停止合作)" }}
            </h2>
            <p class="amber-text">訂單編號：#{{ case.case_id }}</p>
          </div>
          <!--/Card image-->
          <div cascade="true" class="text-left mt-3">
            <div class="row flex-wrap pr-1 pl-1">
              <div class="col-lg-6 col-12">
                <div class="view overlay rounded z-depth-1-half mb-4">
                  <img class="img-fluid" src="{{ case.storeImage }}" alt="" />
                  <a>
                    <div
                      class="mask rgba-white-slight waves-light"
                      mdbWavesEffect
                    ></div>
                  </a>
                </div>
              </div>

              <div class="col-lg-6 col-12 text-left">
                <div class="row bb-1">
                  <div class="col-lg-3 col-6">訂單狀態:</div>
                  <div class="col-lg-9 col-6">
                    <span *ngIf="case.status" id="case-status">
                      {{ case.status | caseStatusFormat }}
                    </span>
                    <span *ngIf="case.postpone_reason">
                      - {{ case.postpone_reason | caseStatusFormat }}
                    </span>
                  </div>
                </div>

                <div *ngIf="case.cancel_status" class="row bb-1 red-text">
                  <div class="col-lg-3 col-6">訂單已被取消:</div>
                  <div class="col-lg-9 col-6">
                    {{ case.cancel_reason | caseStatusFormat }}
                  </div>
                </div>
                <div *ngIf="case.cancel_status" class="row bb-1 red-text">
                  <div class="col-lg-3 col-6">取消時間:</div>
                  <div class="col-lg-9 col-6">
                    {{ case.cancel_datetime | utcDateTimeFormat }}
                  </div>
                </div>
                <div class="row bb-1">
                  <div class="col-lg-3 col-6">購買人:</div>
                  <div class="col-lg-9 col-6">
                    {{ case.user_name }}
                  </div>
                </div>
                <div class="row bb-1">
                  <div class="col-lg-3 col-6">驗證手機:</div>
                  <div class="col-lg-9 col-6">
                    {{ case.user_tel }}
                  </div>
                </div>
                <div class="row bb-1">
                  <div class="col-lg-3 col-6">統一編號:</div>
                  <div class="col-lg-9 col-6">
                    {{ case.invoice_id || invoice_id || "尚未綁定統編" }}
                  </div>
                </div>

                <div class="row bb-1">
                  <div class="col-lg-3 col-6">成立時間:</div>
                  <div class="col-lg-9 col-6">
                    {{ case.ctime | utcDateTimeFormat }}
                  </div>
                </div>
                <div class="row bb-1">
                  <div class="col-lg-3 col-6">入營日<br />(可修改):</div>
                  <div class="col-lg-9 col-6" id="checkin_date">
                    {{ case.checkin_date | utcDateFormat }}
                  </div>
                </div>
                <div class="row bb-1">
                  <div class="col-lg-3 col-6">拔營日<br />(可修改):</div>
                  <div class="col-lg-9 col-6" id="checkout_date">
                    {{ case.checkout_date | utcDateFormat }}
                  </div>
                </div>

                <div class="row bb-1">
                  <div class="col-lg-3 col-6">商品總價:</div>
                  <div class="col-lg-9 col-6" id="total-price">
                    {{ case.stuff_total_price | number : 0 }} 元
                  </div>
                </div>
                <div class="row bb-1">
                  <div class="col-lg-3 col-6">商品總數:</div>
                  <div class="col-lg-9 col-6" id="total-qty">
                    {{ case.stuff_total_quantity }}
                  </div>
                </div>
                <div class="row bb-1">
                  <div class="col-lg-3 col-6">訂位手續費:</div>
                  <div class="col-lg-9 col-6">
                    {{ case.service_fee | number : 0 }} 元
                  </div>
                </div>
                <div class="row bb-1">
                  <div class="col-lg-3 col-6"><strong>總金額:</strong></div>
                  <div class="col-lg-9 col-6">
                    <strong>{{ case.case_total_price | number : 0 }} 元</strong>
                  </div>
                </div>

                <div class="row bb-1 red-text mt-2">
                  <div class="row text-left">
                    <p class="note note-danger">
                      <small>
                        {{
                          case.store_status
                            ? "此訂單不可部分變更天數、日期、區域。"
                            : "店家已下架，修改、取消訂位請聯繫營主。"
                        }}
                      </small>
                    </p>
                  </div>
                </div>

                <div>
                  <div *ngIf="hideBankInfo()">
                    <div class="row mt-1">
                      <div class="col-md-12 labelcum">匯款資料</div>
                    </div>
                    <div
                      class="row bb-1"
                      *ngIf="
                        case.status === 'unpay' ||
                        case.status === 'pending_confirm' ||
                        case.status === 'payment_reminder'
                      "
                    >
                      <div class="col-lg-3 amber-text">繳費期限</div>
                      <div class="col-lg-9 red-text">
                        {{ case.payment_deadline | utcDateFormat }} 13:00 前
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-3"></div>
                      <div class="col amber-text">
                        銀行虛擬帳號限本訂單一次性使用。
                      </div>
                    </div>
                    <div class="row bb-1">
                      <div class="col-3"></div>
                      <div class="col-8 amber-text">如逾期請勿匯款。</div>
                    </div>

                    <div class="row bb-1">
                      <div class="col-lg-3 amber-text">銀行資訊</div>
                      <div class="col-lg-9">
                        國泰世華(013)/西台中分行/<br />
                        {{
                          storeName == "glamping900"
                            ? "台灣愛露營科技有限公司"
                            : "台灣愛露營科技有限公司"
                        }}
                      </div>
                    </div>

                    <div class="row bb-1">
                      <div class="col-lg-3 amber-text">銀行虛擬帳號</div>
                      <div class="col" id="bank-transfer-account">
                        {{
                          case.store_bank.split("-").length == 2
                            ? case.store_bank.split("-")[1]
                            : case.store_bank
                        }}
                      </div>
                      <div
                        class="col-3"
                        mdbWavesEffect
                        (click)="copyBankTransferAccount()"
                      >
                        <a class="text-copy-btn-pc">複製帳號</a>
                      </div>
                    </div>
                    <div class="row bb-1">
                      <div class="col-lg-3 amber-text">
                        <strong>匯款總金額</strong>
                      </div>
                      <div class="col" id="case-total-price">
                        <strong
                          >{{ case.case_total_price | number : 0 }} 元</strong
                        >
                      </div>
                    </div>

                    <!-- <div class="row bb-1 red-text mt-2">
                      <div class="row text-left">
                        <p class="note note-danger">
                          <small>
                            銀行虛擬帳號限本訂單一次使用，誤轉任何金額將收取100元服務費用。
                          </small>
                        </p>
                      </div>
                    </div> -->
                  </div>
                  <div class="row bb-1" *ngIf="isTransferToBoss(case)">
                    <div class="col-lg-3 amber-text">臨時取消</div>
                    <div class="col-lg-9">
                      如您有任何原因，需臨時取消，請直接電話聯繫營主
                    </div>
                  </div>
                  <div
                    class="row w-100 flex-wrap"
                    *ngIf="
                      !case.cancel_status &&
                      case.status !== 'checkout' &&
                      case.store_status &&
                      storeActive
                    "
                  >
                    <div class="col-md-4 col-12">
                      <button
                        class="btn i-green-bg white-text w-100 pl-0 pr-0 pt-3 pb-3"
                        (click)="
                          needBankInfo
                            ? bankInfoModal.show()
                            : pending_confirm()
                        "
                        *ngIf="
                          case.status === 'unpay' ||
                          case.status === 'payment_reminder'
                        "
                      >
                        通知付款
                      </button>
                    </div>
                    <div class="col-md-4 col-12">
                      <button
                        class="btn i-green-bg white-text w-100 pl-0 pr-0 pt-3 pb-3"
                        (click)="cancelModalStep1.show()"
                        id="cancel-case"
                        *ngIf="
                          !isTransferToBoss(case) &&
                          !isTransferToBossAndPostponeByBossDirectly(case) &&
                          editable &&
                          (case.checkin_date | dayDuration) < 15
                        "
                      >
                        全部取消
                      </button>

                      <button
                        class="btn i-green-bg white-text w-100 pl-0 pr-0 pt-3 pb-3"
                        (click)="cancelModalStep2.show()"
                        id="cancel-case"
                        *ngIf="
                          !isTransferToBoss(case) &&
                          !isTransferToBossAndPostponeByBossDirectly(case) &&
                          editable &&
                          (case.checkin_date | dayDuration) >= 15
                        "
                      >
                        全部取消
                      </button>

                      <button
                        class="btn i-green-bg white-text w-100 pl-0 pr-0 pt-3 pb-3"
                        (click)="telCall(bossTel, false)"
                        id="cancel-case-boss-tel"
                        *ngIf="isTransferToBoss(case)"
                      >
                        聯繫營主
                      </button>
                    </div>
                    <div class="col-md-4 col-12">
                      <!-- <button
                        class="btn i-green-d-bg white-text w-100 pl-0 pr-0 pt-3 pb-3"
                        (click)="postponeModal.show()"
                        *ngIf="
                          case.postpone_status &&
                          case.postpone_reason === 'postponed_by_boss_weather'
                        "
                      >
                        自願前往
                      </button> -->

                      <button
                        class="btn i-green-l-bg white-text w-100 pl-0 pr-0 pt-3 pb-3"
                        (click)="editCase()"
                        *ngIf="!isTransferToBoss(case) && editable"
                      >
                        {{ case.postpone_status ? "訂單延期" : "訂單修改" }}
                      </button>
                    </div>
                  </div>
                </div>
                <!-- 在一般情況顯示[修改], 當大聲公公告時顯示[延期] -->
              </div>
            </div>
            <hr />
            <div class="row mt-2">
              <button
                mdbBtn
                *ngIf="case.store_name != 'glamping900'"
                type="button"
                color="info"
                class="waves-light w-100-btn button"
                (click)="telCall(bossTel, false)"
                mdbWavesEffect
              >
                夜衝預約
              </button>
            </div>
            <h3 class="text-center h3-responsive py-2">
              <strong>訂單項目</strong>
            </h3>
            <div class="row ml-sm-5 mr-sm-5 pl-2 pr-2">
              <div
                class="col-lg-3 col-md-12 mb-4"
                *ngFor="let stuff of filterEmptyStuff()"
              >
                <div
                  class="view overlay rounded z-depth-1 waves-light"
                  mdbWavesEffect
                >
                  <img class="card-img-top" src="{{ stuff[6] }}" alt="" />
                </div>
                <mdb-card-body class="mt-1 pr-1 pl-1">
                  <div class="grey-text">
                    項目：{{ stuff[8] }} ({{ stuff[2] }} {{ stuff[1] }})
                    <!-- 項目：{{ stuff[0] }} {{ stuff[2] }} {{ stuff[1] }} -->
                  </div>
                  <div class="grey-text">
                    每日單價：
                    <li
                      *ngFor="let dateKey of _.sortBy(_.keys(stuff[3]))"
                      style="margin-left: 25px"
                    >
                      {{ dateKey | date : "yyyy-MM-dd" }} - 單價：{{
                        stuff[3][dateKey] | number : 0
                      }}
                    </li>
                  </div>
                  <div class="grey-text">
                    項目總金額：{{ stuff[4] | number : 0 }}
                  </div>
                </mdb-card-body>
              </div>
            </div>

            <div *ngIf="case.refund_price.length > 0">
              <hr />
              <h3 class="text-center h1-responsive py-2">
                <strong>退款資訊 / 紀錄</strong>
              </h3>
              <div class="ml-sm-5 mr-sm-5 pl-2 pr-2 bs">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">營地</th>
                        <th scope="col">入住前多少天取消</th>
                        <th scope="col">退款百分比</th>
                        <!-- <th scope="col">訂位手續費＄</th> -->
                        <th scope="col">退款資料處理費＄</th>
                        <th scope="col">取消罰金＄</th>
                        <th scope="col">應退款＄</th>
                        <th scope="col">已退款＄</th>
                        <th scope="col">取消時間</th>
                        <th scope="col">退款時間</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let re of case.refund_price; let r = index">
                        <td>{{ case.refund_stuff[r] }}</td>
                        <td>{{ case.refund_period[r] }}</td>
                        <td>{{ case.refund_percentage[r] }}</td>
                        <!-- <td>{{ case.refund_service_fee[r] }}</td> -->
                        <td
                          *ngIf="
                            caseFreePenalty.indexOf(case.cancel_reason) == -1
                          "
                        >
                          {{
                            case.is_migrate_case
                              ? 100
                              : case.cancel_fee *
                                case.refund_minus_quantity[r] *
                                case.quantity_rates[r]
                          }}
                        </td>
                        <td
                          *ngIf="
                            caseFreePenalty.indexOf(case.cancel_reason) > -1
                          "
                        >
                          0
                        </td>
                        <td
                          *ngIf="
                            caseFreePenalty.indexOf(case.cancel_reason) == -1
                          "
                        >
                          {{
                            case.is_migrate_case
                              ? case.refund_penalty[r] - 100
                              : case.refund_penalty[r] -
                                case.cancel_fee *
                                  case.refund_minus_quantity[r] *
                                  case.quantity_rates[r] -
                                case.refund_service_fee[r]
                          }}
                        </td>
                        <td
                          *ngIf="
                            caseFreePenalty.indexOf(case.cancel_reason) > -1
                          "
                        >
                          0
                        </td>
                        <td>{{ case.refund_price[r] }}</td>
                        <td *ngIf="case.refunded_price[r] === -1"></td>
                        <td *ngIf="case.refunded_price[r] !== -1">
                          {{ case.refunded_price[r] }}
                        </td>
                        <td>{{ case.refund_time[r] | utcDateTimeFormat }}</td>
                        <td *ngIf="case.refunded_price[r] !== -1">
                          {{ case.refunded_time[r] | utcDateTimeFormat }}
                        </td>
                        <td *ngIf="case.refunded_price[r] === -1">尚未退款</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>總計：</td>
                        <td>{{ refundPrice }}</td>
                        <td>{{ refundedPrice }}</td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                class="ss"
                *ngFor="let re of case.refund_price; let r = index"
              >
                <div class="row">
                  <div class="col-4">
                    <div class="col-md-12 labelcum">營地</div>
                  </div>
                  <div class="col-8">
                    {{ case.refund_stuff[r] }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="col-md-12 labelcum">入住前多少天取消</div>
                  </div>
                  <div class="col-8">
                    {{ case.refund_period[r] }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="col-md-12 labelcum">退款百分比</div>
                  </div>
                  <div class="col-8">
                    {{ case.refund_percentage[r] }}
                  </div>
                </div>
                <!-- <div class="row">
                  <div class="col-4">
                    <div class="col-md-12 labelcum">訂位手續費＄</div>
                  </div>
                  <div class="col-8">
                    {{ case.refund_service_fee[r] }}
                  </div>
                </div> -->
                <div class="row">
                  <div class="col-4">
                    <div class="col-md-12 labelcum">退款資料處理費＄</div>
                  </div>
                  <div
                    class="col-8"
                    *ngIf="caseFreePenalty.indexOf(case.cancel_reason) == -1"
                  >
                    {{
                      case.is_migrate_case
                        ? 100
                        : case.cancel_fee *
                          case.refund_minus_quantity[r] *
                          case.quantity_rates[r]
                    }}
                  </div>
                  <div
                    class="col-8"
                    *ngIf="caseFreePenalty.indexOf(case.cancel_reason) > -1"
                  >
                    0
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="col-md-12 labelcum">取消罰金＄</div>
                  </div>
                  <div
                    class="col-8"
                    *ngIf="caseFreePenalty.indexOf(case.cancel_reason) == -1"
                  >
                    {{
                      case.is_migrate_case
                        ? case.refund_penalty[r] - 100
                        : case.refund_penalty[r] -
                          case.cancel_fee *
                            case.refund_minus_quantity[r] *
                            case.quantity_rates[r] -
                          case.refund_service_fee[r]
                    }}
                  </div>
                  <div
                    class="col-8"
                    *ngIf="caseFreePenalty.indexOf(case.cancel_reason) > -1"
                  >
                    0
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="col-md-12 labelcum">應退款＄</div>
                  </div>
                  <div class="col-8">
                    {{ case.refund_price[r] }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="col-md-12 labelcum">已退款＄</div>
                  </div>
                  <div class="col-8">
                    <div *ngIf="case.refunded_price[r] === -1"></div>
                    <div *ngIf="case.refunded_price[r] !== -1">
                      {{ case.refunded_price[r] }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="col-md-12 labelcum">取消時間</div>
                  </div>
                  <div class="col-8">
                    {{ case.refund_time[r] | utcDateTimeFormat }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="col-md-12 labelcum">退款時間</div>
                  </div>
                  <div class="col-8">
                    <div *ngIf="case.refunded_price[r] !== -1">
                      {{ case.refunded_time[r] | utcDateTimeFormat }}
                    </div>
                    <div *ngIf="case.refunded_price[r] === -1">尚未退款</div>
                  </div>
                </div>
                <hr />
              </div>
            </div>
            <div *ngIf="invoices.length > 0">
              <hr />
              <h3 class="text-center h1-responsive py-2">
                <strong>發票資訊 / 紀錄</strong>
              </h3>
              <div class="ml-sm-5 mr-sm-5 pl-2 pr-2 bs">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">開立時間</th>
                        <th scope="col">發票號碼</th>
                        <th scope="col">隨機碼</th>
                        <th scope="col">金額</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let invoice of invoices">
                        <td>{{ invoice.ctime | utcDateTimeFormat }}</td>
                        <td>{{ invoice.invoice_no }}</td>
                        <td>{{ invoice.random_no }}</td>
                        <td>{{ invoice.fee }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="ss" *ngFor="let invoice of invoices">
                <div class="row">
                  <div class="col-4">
                    <div class="col-md-12 labelcum">開立時間</div>
                  </div>
                  <div class="col-8">
                    {{ invoice.ctime | utcDateTimeFormat }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="col-md-12 labelcum">發票號碼</div>
                  </div>
                  <div class="col-8">
                    {{ invoice.invoice_no }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="col-md-12 labelcum">隨機碼</div>
                  </div>
                  <div class="col-8">
                    {{ invoice.random_no }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="col-md-12 labelcum">金額</div>
                  </div>
                  <div class="col-8">
                    {{ invoice.fee }}
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>
          <!--/.Card content-->
        </mdb-card>
        <div class="smallhero">
          <div class="row">
            <h3 class="card-header-title h3-responsive center">
              {{ case.store_alias }} 訂單明細
              {{ storeActive ? "" : " (愛露營與店家已停止合作)" }}
            </h3>
          </div>
          <!-- <div class="case-bg"> -->
          <div class="case-bg row bottom-line-dg mt-3">
            <div class="col-4">訂單編號：</div>
            <div class="col-8">{{ case.case_id }}</div>
          </div>
          <div class="case-bg row bottom-line-dg">
            <div class="col-4">訂單狀態：</div>
            <div class="col-8">
              <span *ngIf="case.status" id="case-status">
                {{ case.status | caseStatusFormat }}
              </span>
              <span *ngIf="case.postpone_reason">
                - {{ case.postpone_reason | caseStatusFormat }}
              </span>
            </div>
          </div>
          <div class="case-bg row bottom-line-dg" *ngIf="case.cancel_status">
            <div class="col-4">訂單已取消:</div>
            <div class="col-8">
              {{ case.cancel_reason | caseStatusFormat }}
            </div>
          </div>
          <div class="case-bg row bottom-line-dg" *ngIf="case.cancel_status">
            <div class="col-4">取消時間:</div>
            <div class="col-8">
              {{ case.cancel_datetime | utcDateTimeFormat }}
            </div>
          </div>
          <div class="case-bg row bottom-line-dg">
            <div class="col-4">購買人:</div>
            <div class="col-8">{{ case.user_name }}</div>
          </div>
          <div class="case-bg row bottom-line-dg">
            <div class="col-4">驗證手機:</div>
            <div class="col-8">{{ case.user_tel }}</div>
          </div>
          <div class="case-bg row bottom-line-dg">
            <div class="col-4">統一編號:</div>
            <div class="col-8">
              {{ case.invoice_id || invoice_id || "尚未綁定統編" }}
            </div>
          </div>
          <div class="case-bg row bottom-line-dg">
            <div class="col-4">成立時間:</div>
            <div class="col-8">{{ case.ctime | utcDateTimeFormat }}</div>
          </div>
          <div class="case-bg row bottom-line-dg">
            <div class="col-4">入營日:</div>
            <div class="col-8">{{ case.checkin_date | utcDateFormat }}</div>
          </div>
          <div class="case-bg row bottom-line-dg">
            <div class="col-4">拔營日:</div>
            <div class="col-8">{{ case.checkout_date | utcDateFormat }}</div>
          </div>
          <div class="case-bg row bottom-line-dg">
            <div class="col-4">商品總價:</div>
            <div class="col-8">
              {{ case.stuff_total_price | number : 0 }} 元
            </div>
          </div>
          <div class="case-bg row bottom-line-dg">
            <div class="col-4">商品總數:</div>
            <div class="col-8">{{ case.stuff_total_quantity }}</div>
          </div>
          <div class="case-bg row bottom-line-dg">
            <div class="col-4">訂位手續費:</div>
            <div class="col-8">{{ case.service_fee | number : 0 }} 元</div>
          </div>
          <div class="case-bg row bottom-line-dg">
            <div class="col-4"><strong>總金額:</strong></div>
            <div class="col-8">
              <strong>{{ case.case_total_price | number : 0 }} 元</strong>
            </div>
          </div>
          <!--<div class="row mt-1">
            <p class="note note-danger">
              <small>
                {{
                  case.store_status
                    ? "此訂單不可部分變更天數、日期、區域。"
                    : "店家已下架，修改、取消訂位請聯繫營主。"
                }}
              </small>
            </p>
          </div>-->
          <div *ngIf="hideBankInfo()">
            <div class="row">
              <div class="col-md-12 labelcum">匯款資料</div>
            </div>
            <div
              class="row bb-1"
              *ngIf="
                case.status === 'unpay' ||
                case.status === 'pending_confirm' ||
                case.status === 'payment_reminder'
              "
            >
              <div class="col-4 i-green">繳費期限</div>
              <div class="col-8 red-text">
                {{ case.payment_deadline | utcDateFormat }} 13:00 前
              </div>
            </div>
            <div class="row">
              <div class="col-4"></div>
              <div class="col-8 i-green">銀行虛擬帳號限本訂單一次性使用。</div>
            </div>
            <div class="row bb-1">
              <div class="col-4"></div>
              <div class="col-8 i-green">如逾期請勿匯款。</div>
            </div>
            <div class="row bb-1">
              <div class="col-4 i-green">銀行資訊</div>
              <div class="col-8">
                國泰世華(013)/西台中分行/<br />
                {{
                  storeName == "glamping900"
                    ? "台灣愛露營科技有限公司"
                    : "台灣愛露營科技有限公司"
                }}
              </div>
            </div>
            <div class="row bb-1">
              <div class="col-4 i-green">銀行虛擬帳號</div>
              <div class="col-4" id="bank-transfer-account">
                {{
                  case.store_bank.split("-").length == 2
                    ? case.store_bank.split("-")[1]
                    : case.store_bank
                }}
              </div>
              <div
                class="col-4"
                mdbWavesEffect
                (click)="copyBankTransferAccount()"
              >
                <a class="text-copy-btn-mobile">複製帳號</a>
              </div>
            </div>
            <div class="row bb-1">
              <div class="col-4 i-green"><strong>匯款總金額</strong></div>
              <div class="col-4" id="case-total-price">
                <strong>{{ case.case_total_price | number : 0 }} 元</strong>
              </div>
            </div>
          </div>
          <!-- <div class="row bb-1 red-text mt-2">
            <div class="row text-left">
              <p class="note note-danger">
                <small>
                  銀行虛擬帳號限本訂單一次使用，誤轉任何金額將收取100元服務費用。
                </small>
              </p>
            </div>
          </div> -->

          <div class="row bb-1" *ngIf="isTransferToBoss(case)">
            <div class="col-4 i-green">臨時取消</div>
            <div class="col-8">
              如您有任何原因，需臨時取消，請直接電話聯繫營主
            </div>
          </div>
          <div
            class="row w-100 flex-wrap mt-3"
            *ngIf="
              !case.cancel_status &&
              case.status !== 'checkout' &&
              case.store_status &&
              storeActive
            "
          >
            <div class="col-4 pt-0 pl-1 pr-1">
              <button
                class="btn i-green-bg white-text w-100 px-3 ml-0 mr-1"
                (click)="
                  needBankInfo ? bankInfoModal.show() : pending_confirm()
                "
                *ngIf="
                  case.status === 'unpay' || case.status === 'payment_reminder'
                "
              >
                通知付款
              </button>
            </div>
            <div class="col-4 pt-0 pl-1 pr-1">
              <button
                class="btn i-green-d-bg white-text w-100 px-3 ml-0 mr-1"
                (click)="cancelModalStep1.show()"
                id="cancel-case"
                *ngIf="
                  !isTransferToBoss(case) &&
                  !isTransferToBossAndPostponeByBossDirectly(case) &&
                  editable &&
                  (case.checkin_date | dayDuration) < 15
                "
              >
                全部取消
              </button>
              <button
                class="btn i-green-bg white-text w-100 pl-0 pr-0 pt-3 pb-3"
                (click)="cancelModalStep2.show()"
                id="cancel-case"
                *ngIf="
                  !isTransferToBoss(case) &&
                  !isTransferToBossAndPostponeByBossDirectly(case) &&
                  editable &&
                  (case.checkin_date | dayDuration) >= 15
                "
              >
                全部取消
              </button>

              <button
                class="btn i-green-d-bg white-text w-100 px-3 ml-0 mr-1"
                (click)="telCall(bossTel, false)"
                id="cancel-case-boss-tel"
                *ngIf="isTransferToBoss(case)"
              >
                聯繫營主
              </button>
            </div>
            <div class="col-4 pt-0 pl-1 pr-1">
              <!-- <button
                class="btn i-green-l-bg white-text w-100 px-3 ml-0 mr-1"
                (click)="postponeModal.show()"
                *ngIf="
                  case.postpone_status &&
                  case.postpone_reason === 'postponed_by_boss_weather'
                "
              >
                自願前往
              </button> -->
              <button
                class="btn i-green-l-bg white-text w-100 px-3 ml-0 mr-1"
                (click)="editCase()"
                *ngIf="!isTransferToBoss(case) && editable"
              >
                {{ case.postpone_status ? "訂單延期" : "訂單修改" }}
              </button>
            </div>
          </div>
          <div class="row mt-2">
            <button
              mdbBtn
              *ngIf="case.store_name != 'glamping900'"
              type="button"
              color="info"
              class="waves-light w-100-btn button"
              (click)="telCall(bossTel, false)"
              mdbWavesEffect
            >
              夜衝預約
            </button>
          </div>
          <div class="row mt-2">
            <div class="col-md-12 labelcum">訂單項目</div>
          </div>
          <div class="row bottom_line" *ngFor="let stuff of filterEmptyStuff()">
            <div class="col-12">
              <div
                class="view overlay rounded z-depth-1 waves-light"
                mdbWavesEffect
              >
                <img class="card-img-top" src="{{ stuff[6] }}" alt="" />
              </div>
            </div>
            <div class="col-12 mt-3 grey-text">
              <div class="row bb-1">
                <div class="col-4">項目：</div>
                <div class="col-8">
                  {{ stuff[8] }} ({{ stuff[2] }} {{ stuff[1] }})
                  <!-- 項目：{{ stuff[0] }} {{ stuff[2] }} {{ stuff[1] }} -->
                </div>
              </div>
              <div class="row bb-1">
                <div class="col-4">每日單價：</div>
                <div class="col-8">
                  <div *ngFor="let dateKey of _.sortBy(_.keys(stuff[3]))">
                    {{ dateKey | date : "yyyy-MM-dd" }} <br />
                    單價：{{ stuff[3][dateKey] | number : 0 }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-4">總金額：</div>
                <div class="col-8">
                  {{ stuff[4] | number : 0 }}
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="case.refund_price">
            <div class="row mt-2">
              <div class="col-md-12 labelcum">退款資訊 / 紀錄</div>
            </div>
            <div class="table-responsive bighero">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">營地</th>
                    <th scope="col">入住前多少天取消</th>
                    <th scope="col">退款百分比</th>
                    <!-- <th scope="col">訂位手續費＄</th> -->
                    <th scope="col">退款資料處理費＄</th>
                    <th scope="col">取消罰金＄</th>
                    <th scope="col">應退款＄</th>
                    <th scope="col">已退款＄</th>
                    <th scope="col">取消時間</th>
                    <th scope="col">退款時間</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let re of case.refund_price; let r = index">
                    <td>{{ case.refund_stuff[r] }}</td>
                    <td>{{ case.refund_period[r] }}</td>
                    <td>{{ case.refund_percentage[r] }}</td>
                    <!-- <td>{{ case.refund_service_fee[r] }}</td> -->
                    <td
                      *ngIf="caseFreePenalty.indexOf(case.cancel_reason) == -1"
                    >
                      {{
                        case.is_migrate_case
                          ? 100
                          : case.cancel_fee *
                            case.refund_minus_quantity[r] *
                            case.quantity_rates[r]
                      }}
                    </td>
                    <td
                      *ngIf="caseFreePenalty.indexOf(case.cancel_reason) > -1"
                    >
                      0
                    </td>
                    <td
                      *ngIf="caseFreePenalty.indexOf(case.cancel_reason) == -1"
                    >
                      {{
                        case.is_migrate_case
                          ? case.refund_penalty[r] - 100
                          : case.refund_penalty[r] -
                            case.cancel_fee *
                              case.refund_minus_quantity[r] *
                              case.quantity_rates[r] -
                            case.refund_service_fee[r]
                      }}
                    </td>
                    <td
                      *ngIf="caseFreePenalty.indexOf(case.cancel_reason) > -1"
                    >
                      0
                    </td>
                    <td>{{ case.refund_price[r] }}</td>
                    <td *ngIf="case.refunded_price[r] === -1"></td>
                    <td *ngIf="case.refunded_price[r] !== -1">
                      {{ case.refunded_price[r] }}
                    </td>
                    <td>{{ case.refund_time[r] | utcDateTimeFormat }}</td>
                    <td *ngIf="case.refunded_price[r] !== -1">
                      {{ case.refunded_time[r] | utcDateTimeFormat }}
                    </td>
                    <td *ngIf="case.refunded_price[r] === -1">尚未退款</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>總計：</td>
                    <td>{{ refundPrice }}</td>
                    <td>{{ refundedPrice }}</td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="smallhero"
              *ngFor="let re of case.refund_price; let r = index"
            >
              <div class="row">
                <div class="col-4">
                  <div class="col-md-12 labelcum">營地</div>
                </div>
                <div class="col-8">
                  {{ case.refund_stuff[r] }}
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <div class="col-md-12 labelcum">入住前多少天取消</div>
                </div>
                <div class="col-8">
                  {{ case.refund_period[r] }}
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <div class="col-md-12 labelcum">退款百分比</div>
                </div>
                <div class="col-8">
                  {{ case.refund_percentage[r] }}
                </div>
              </div>
              <!-- <div class="row">
                <div class="col-4">
                  <div class="col-md-12 labelcum">訂位手續費＄</div>
                </div>
                <div class="col-8">{{ case.refund_service_fee[r] }}</div>
              </div> -->
              <div class="row">
                <div class="col-4">
                  <div class="col-md-12 labelcum">退款資料處理費＄</div>
                </div>
                <div
                  class="col-8"
                  *ngIf="caseFreePenalty.indexOf(case.cancel_reason) == -1"
                >
                  {{
                    case.is_migrate_case
                      ? 100
                      : case.cancel_fee *
                        case.refund_minus_quantity[r] *
                        case.quantity_rates[r]
                  }}
                </div>
                <div
                  class="col-8"
                  *ngIf="caseFreePenalty.indexOf(case.cancel_reason) > -1"
                >
                  0
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <div class="col-md-12 labelcum">取消罰金＄</div>
                </div>
                <div
                  class="col-8"
                  *ngIf="caseFreePenalty.indexOf(case.cancel_reason) == -1"
                >
                  {{
                    case.is_migrate_case
                      ? case.refund_penalty[r] - 100
                      : case.refund_penalty[r] -
                        case.cancel_fee *
                          case.refund_minus_quantity[r] *
                          case.quantity_rates[r] -
                        case.refund_service_fee[r]
                  }}
                </div>
                <div
                  class="col-8"
                  *ngIf="caseFreePenalty.indexOf(case.cancel_reason) > -1"
                >
                  0
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <div class="col-md-12 labelcum">應退款＄</div>
                </div>
                <div class="col-8">
                  {{ case.refund_price[r] }}
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <div class="col-md-12 labelcum">已退款＄</div>
                </div>
                <div class="col-8">
                  <div *ngIf="case.refunded_price[r] === -1"></div>
                  <div *ngIf="case.refunded_price[r] !== -1">
                    {{ case.refunded_price[r] }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <div class="col-md-12 labelcum">取消時間</div>
                </div>
                <div class="col-8">
                  {{ case.refund_time[r] | utcDateTimeFormat }}
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <div class="col-md-12 labelcum">退款時間</div>
                </div>
                <div class="col-8">
                  <div *ngIf="case.refunded_price[r] !== -1">
                    {{ case.refunded_time[r] | utcDateTimeFormat }}
                  </div>
                  <div *ngIf="case.refunded_price[r] === -1">尚未退款</div>
                </div>
              </div>
              <hr />
            </div>
          </div>
          <div *ngIf="invoices">
            <div class="row mt-2">
              <div class="col-md-12 labelcum">發票資訊 / 紀錄</div>
            </div>
            <div class="table-responsive bighero">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">開立時間</th>
                    <th scope="col">發票號碼</th>
                    <th scope="col">隨機碼</th>
                    <th scope="col">金額</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let invoice of invoices">
                    <td>{{ invoice.ctime | utcDateTimeFormat }}</td>
                    <td>{{ invoice.invoice_no }}</td>
                    <td>{{ invoice.random_no }}</td>
                    <td>{{ invoice.fee }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="smallhero" *ngFor="let invoice of invoices">
              <div class="row">
                <div class="col-4">
                  <div class="col-md-12 labelcum">開立時間</div>
                </div>
                <div class="col-8">
                  {{ invoice.ctime | utcDateTimeFormat }}
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <div class="col-md-12 labelcum">發票號碼</div>
                </div>
                <div class="col-8">
                  {{ invoice.invoice_no }}
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <div class="col-md-12 labelcum">隨機碼</div>
                </div>
                <div class="col-8">
                  {{ invoice.random_no }}
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <div class="col-md-12 labelcum">金額</div>
                </div>
                <div class="col-8">
                  {{ invoice.fee }}
                </div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    mdbModal
    #bankInfoModal="mdb-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="bankInfoModalLabel"
    aria-hidden="true"
    [config]="{ backdrop: false, ignoreBackdropClick: true }"
  >
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header text-center">
          <h4 class="modal-title w-100 font-weight-bold">
            請填寫您的銀行匯款資料
          </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="bankInfoModal.hide()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body mx-3">
          <div class="md-form">
            <mdb-icon fas icon="arrow-circle-right" class="prefix"></mdb-icon>

            <!-- <i class="fa fa-envelope prefix grey-text"></i> -->
            <input
              type="text"
              id="materialFormBankDesc"
              name="bankDesc"
              class="form-control validate"
              [(ngModel)]="user_bank_description"
              mdbInput
              [mdbValidation]="false"
              placeholder="銀行代碼："
            />

            <div class="row bb-1-p0">
              <div class="row text-left">
                <p class="note note-danger">
                  <small style="font-size: 11px">
                    請輸入正確<strong>銀行代碼</strong
                    ><br />避免無法確認訂單(空位釋出)，或延遲退款程序。
                  </small>
                </p>
              </div>
            </div>
          </div>

          <div class="md-form mb-4">
            <!-- <i class="fa fa-lock prefix grey-text"></i> -->
            <mdb-icon fas icon="arrow-circle-right" class="prefix"></mdb-icon>

            <input
              type="text"
              id="materialFormBank"
              name="bank"
              class="form-control validate"
              [(ngModel)]="user_bank"
              mdbInput
              [mdbValidation]="false"
              placeholder="銀行帳號："
            />
            <div class="row bb-1-p0">
              <div class="row text-left">
                <p class="note note-danger">
                  <small style="font-size: 11px">
                    請輸入正確<strong>銀行帳號</strong
                    ><br />避免無法確認訂單(空位釋出)，或延遲退款程序。
                  </small>
                </p>
              </div>
            </div>
          </div>
          <div class="md-form mb-4">
            <mdb-icon fas icon="arrow-circle-right" class="prefix"></mdb-icon>

            <input
              type="text"
              id="materialFormInvoiceId"
              name="invoice_id"
              class="form-control validate"
              [(ngModel)]="invoice_id"
              mdbInput
              [mdbValidation]="false"
              placeholder="統一編號 不需請空白"
            />
            <mdb-error *ngIf="!invoiceValidators()"
              >請留白或是請輸入8個數字</mdb-error
            >
            <div class="row bb-1-p0">
              <div class="row text-left">
                <p class="note note-danger">
                  <small style="font-size: 11px">
                    愛露營服務費用皆有開電子發票，營地費用發票請洽詢營主。
                  </small>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <button
            type="button"
            mdbBtn
            id="pending-confirm"
            class="btn i-green-bg white-text"
            mdbWavesEffect
            (click)="pending_confirm(); bankInfoModal.hide()"
            [disabled]="
              user_bank.length == 0 ||
              user_bank_description.length == 0 ||
              !invoiceValidators()
            "
          >
            通知已付款
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- 確定取消 Step1 -->
  <div
    mdbModal
    #cancelModalStep1="mdb-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="caseCancelModalLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-scrollable"
      role="document"
      *ngIf="(case.checkin_date | dayDuration) < 15"
    >
      <div class="modal-content">
        <div class="modal-header text-center">
          <h4 class="modal-title w-100 font-weight-bold">溫馨提醒</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="cancelModalStep1.hide()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body mx-auto">
          <div class="row">
            <p class="note note-danger">
              <small>
                本筆訂單在距離露營日 第{{
                  case.checkin_date | dayDuration
                }}天取消，
                距離露營日14(含)天內，已無法退款100%，詳見退費規則，確定取消嗎？
              </small>
            </p>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            mdbBtn
            size="sm"
            class="waves-light mr-auto i-green-l-bg white-text"
            aria-label="Close"
            (click)="cancelModalStep1.hide()"
            mdbWavesEffect
          >
            離開
          </button>
          <button
            type="button"
            mdbBtn
            size="sm"
            id="confirmed-cancel-case"
            class="relative waves-light i-green-d-bg white-text"
            (click)="cancelModalStep2.show(); cancelModalStep1.hide()"
            mdbWavesEffect
            [disabled]="
              user_bank.length == 0 ||
              user_bank_description.length == 0 ||
              !invoiceValidators()
            "
          >
            下一步
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- 確定取消 Step2 -->
  <div
    mdbModal
    #cancelModalStep2="mdb-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="caseCancelModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header text-center">
          <h4 class="modal-title w-100 font-weight-bold">確定取消？</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="cancelModalStep2.hide()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body mx-auto">
          <div *ngIf="rules">
            退費規則:
            <div *ngFor="let r of rules.refund_rule" style="font-size: 12px">
              <div *ngIf="r.inday === '14'">
                抵達日前<span class="tag-balaced-outline"> 15</span>
                天以上可延期、可取消，退款<span class="tag-balaced-outline">
                  {{ r.percent }}</span
                >
                %
              </div>
              <div *ngIf="r.inday === '7'">
                抵達日前<span class="tag-balaced-outline"> 7 - 13</span>
                天內不可延期、可取消，退款<span class="tag-balaced-outline">
                  {{ r.percent }}</span
                >
                %
              </div>
              <div *ngIf="r.inday === '3'">
                抵達日前<span class="tag-balaced-outline"> 3 - 6 </span>
                天內不可延期、可取消，退款<span class="tag-balaced-outline">
                  {{ r.percent }}</span
                >
                %
              </div>
              <div *ngIf="r.inday === '2'">
                抵達日前<span class="tag-balaced-outline"> 2</span>
                天內不可延期、可取消，退款<span class="tag-balaced-outline">
                  {{ r.percent }}</span
                >
                %
              </div>
            </div>
            <div style="font-size: 12px">
              任何退費，訂位者需負擔退款資料處理費每單位每天50元，
              該費用會由退款金額中扣除。
            </div>
          </div>

          <div class="row text-left" style="font-size: 12px">
            <a target="_blank" href="/icamping-rule"
              >點我查看『愛露營訂位使用規則』</a
            >
          </div>

          <hr />
          <div class="row text-left">
            <p class="note note-danger">
              <small style="font-size: 11px">
                附註ㄧ：請確認您的帳戶資訊，以便後續退款流程順利。<br />
                附註二：如未繳款請在以下兩個欄位內皆填入0即可。<br />
                附註三：取消訂單後，無法救回、延期、或做任何改變。
              </small>
            </p>
          </div>

          <div class="md-form mbn-20">
            <mdb-icon fas icon="arrow-circle-right" class="prefix"></mdb-icon>
            <input
              type="text"
              id="materialFormCancelBankDesc"
              name="cancelBankDesc"
              class="form-control validate"
              [(ngModel)]="user_bank_description"
              mdbInput
              [mdbValidation]="false"
              placeholder="銀行代碼："
            />
            <!-- <div class="alert alert-danger bank-desc" role="alert">
              請輸入正確<strong>銀行代碼</strong>，避免無法確認訂單(空位釋出)，或延遲退款程序。
            </div> -->
            <div class="row bb-1-p0">
              <div class="row text-left">
                <p class="note note-danger">
                  <small style="font-size: 11px">
                    請輸入正確<strong>銀行代碼</strong
                    ><br />避免無法確認訂單(空位釋出)，或延遲退款程序。
                  </small>
                </p>
              </div>
            </div>
          </div>

          <div class="md-form mb-4">
            <mdb-icon fas icon="arrow-circle-right" class="prefix"></mdb-icon>

            <input
              type="text"
              id="materialFormCancelBank"
              name="cancelBank"
              class="form-control validate"
              [(ngModel)]="user_bank"
              mdbInput
              [mdbValidation]="false"
              placeholder="銀行帳號："
            />
            <!-- <div class="alert alert-danger bank-desc" role="alert">
              請輸入正確<strong>銀行帳號</strong>，避免無法確認訂單(空位釋出)，或延遲退款程序。
            </div> -->
            <div class="row bb-1-p0">
              <div class="row text-left">
                <p class="note note-danger">
                  <small style="font-size: 11px">
                    請輸入正確<strong>銀行帳號</strong
                    ><br />避免無法確認訂單(空位釋出)，或延遲退款程序。
                  </small>
                </p>
              </div>
            </div>
          </div>
          <div class="md-form mb-4">
            <mdb-icon fas icon="arrow-circle-right" class="prefix"></mdb-icon>

            <input
              type="text"
              id="materialFormCancelInvoiceId"
              name="cancel_invoice_id"
              class="form-control validate"
              [(ngModel)]="invoice_id"
              mdbInput
              [mdbValidation]="false"
              placeholder="統一編號 不需請空白"
            />
            <mdb-error *ngIf="!invoiceValidators()"
              >請留白或是請輸入8個數字</mdb-error
            >
            <!-- <div class="alert alert-danger bank-desc" role="alert">
              愛露營服務費用皆有開電子發票，營地費用發票請洽詢營主。
            </div> -->
            <div class="row bb-1-p0">
              <div class="row text-left">
                <p class="note note-danger">
                  <small style="font-size: 11px">
                    愛露營服務費用皆有開電子發票，營地費用發票請洽詢營主。
                  </small>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            mdbBtn
            size="sm"
            class="waves-light mr-auto i-green-l-bg white-text"
            aria-label="Close"
            (click)="cancelModalStep2.hide()"
            mdbWavesEffect
          >
            不要取消
          </button>
          <button
            type="button"
            mdbBtn
            size="sm"
            id="confirmed-cancel-case"
            class="relative waves-light i-green-d-bg white-text"
            (click)="cancelCase(); cancelModalStep2.hide()"
            mdbWavesEffect
            [disabled]="
              user_bank.length == 0 ||
              user_bank_description.length == 0 ||
              !invoiceValidators()
            "
          >
            確定取消
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    mdbModal
    #postponeModal="mdb-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="postponeModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header text-center">
          <h4 class="modal-title w-100 font-weight-bold">確定自願前往？</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="postponeModal.hide()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body mx-auto"></div>

        <div class="modal-footer">
          <button
            type="button"
            mdbBtn
            size="sm"
            class="waves-light mr-auto i-green-d-bg white-text"
            aria-label="Close"
            (click)="postponeModal.hide()"
            mdbWavesEffect
          >
            我再想想
          </button>
          <button
            type="button"
            mdbBtn
            size="sm"
            id="confirmed-cancel-case"
            class="relative waves-light i-green-bg white-text"
            (click)="postponeRevert(); postponeModal.hide()"
            mdbWavesEffect
          >
            確定前往
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
